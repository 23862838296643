import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'
import { Button, Col, Form, Input, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { publicService } from 'services'
import { locationService } from 'services'
import useGetProvince from 'state/location/hooks/useGetLocation'
import { IProvinceData } from 'state/location/actions'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import TableBase from 'components/Table/TableBase'

const columns = [
  {
    title: 'Tỉnh/thành',
    dataIndex: 'province',
    render: (province) => (
      <div>
        <p>{province}</p>
      </div>
    ),
  },
  {
    title: 'Tên',
    dataIndex: 'title',
    render: (title) => (
      <div>
        <p>{title}</p>
      </div>
    ),
  },
  {
    title: <p style={{ textAlign: 'left' }}>Địa chỉ</p>,
    dataIndex: 'address',
    render: (address) => (
      <div style={{ textAlign: 'left' }}>
        <p>{address}</p>
      </div>
    ),
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone_number',
    render: (phone_number) => (
      <div>
        <p>{phone_number}</p>
      </div>
    ),
  },
]

const Page = () => {
  const [form] = Form.useForm()
  const { setFieldsValue } = form

  const { provinceList, fetchProvince } = useGetProvince()

  useEffect(() => {
    Promise.all([fetchProvince()])
  }, [fetchProvince])

  const [transporterBranches, setTransporterBranches] = useState<any>(null)
  const [districtList, setDistrictList] = useState<IProvinceData[]>([])
  const [wardList, setWardList] = useState<IProvinceData[]>([])
  const [loadingList, setLoadingList] = useState<boolean>(true)
  const [paramGetTransporterBranches, setParamGetTransporterBranches] = useState({
    page: 1,
    per_page: 10,
  })

  useEffect(() => {
    setLoadingList(true)
    publicService.getTransporterBranches(paramGetTransporterBranches, (data) => {
      setTransporterBranches(data)
      setLoadingList(false)
    }),
      []
  }, [paramGetTransporterBranches])

  const handleChangeLocation = useCallback(
    async (type, id) => {
      if (type === 'province') {
        setFieldsValue({
          district_id: undefined,
          ward_id: undefined,
        })
        await locationService.getDistrict(id, (res) => setDistrictList(res?.districts))
        setWardList([])
      }
      if (type === 'district') {
        setFieldsValue({
          ward_id: undefined,
        })
        await locationService.getWard(id, (res) => setWardList(res?.wards))
      }
    },
    [setFieldsValue]
  )

  const handleSearch = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setParamGetTransporterBranches((prev) => ({
          ...prev,
          page: 1,
          keyword: values.keyword || undefined,
          province: provinceList?.find((item) => item?.id === +values.province)?.title || undefined,
          district: districtList?.find((item) => item?.id === +values.district)?.title || undefined,
          ward: wardList?.find((item) => item?.id === +values.ward)?.title || undefined,
          type: values.type || undefined,
        }))
      })
      .catch((error) => {
        console.error('Validation failed:', error)
      })
  }, [districtList, form, provinceList, wardList])

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: ' Thông tin bưu cục',
              href: '',
            },
          ]}
        />

        <Content>
          <div>
            <Form form={form} layout='horizontal' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Row gutter={[12, 12]}>
                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 4 }}>Từ khoá</p>
                  <Form.Item name='keyword'>
                    <Input placeholder='Tên, địa chỉ' style={{ height: 40 }} />
                  </Form.Item>
                </Col>

                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 4 }}>Tỉnh / Thành</p>
                  <Form.Item name='province'>
                    <select
                      className='custom-select'
                      onChange={(e) => {
                        handleChangeLocation('province', e?.target?.value)
                      }}
                    >
                      <option value=''>Chọn tỉnh / thành</option>
                      {provinceList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>

                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 4 }}>Quận / Huyện</p>
                  <Form.Item name='district'>
                    <select className='custom-select' onChange={(e) => handleChangeLocation('district', e?.target?.value)}>
                      <option value=''>Chọn quận / huyện</option>
                      {districtList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>

                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 4 }}>Phường / Xã</p>
                  <Form.Item name='ward'>
                    <select className='custom-select'>
                      <option value=''>Chọn phường / xã</option>
                      {wardList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>

                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 4 }}>Loại</p>
                  <Form.Item name='type'>
                    <select className='custom-select' onChange={(e) => handleChangeLocation('district', e?.target?.value)}>
                      <option value=''>Loại</option>
                      <option value='SERVICE'>Điểm dịch vụ</option>
                      <option value='SHIPPING'>Điểm gửi hàng</option>
                    </select>
                  </Form.Item>
                </Col>

                <Col span={12} md={{ span: 5 }} lg={{ span: 4 }}>
                  <ActionGroup>
                    <Button onClick={handleSearch}>
                      <SearchOutlined /> Tìm kiếm
                    </Button>
                  </ActionGroup>
                </Col>
              </Row>
            </Form>
          </div>

          <TableBase
            columns={columns}
            dataSource={transporterBranches?.data?.branches}
            scroll={{ x: 1000 }}
            rowKey={(row) => row.id}
            pagination={{
              current: transporterBranches?.data?.meta?.current_page,
              pageSize: transporterBranches?.data?.meta?.per_page,
              total: transporterBranches?.data?.meta?.total,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setParamGetTransporterBranches((prev) => ({
                  ...prev,
                  page: page,
                  per_page: pageSize,
                }))
              },
              showTotal: (total) => `Tổng ${total}`,
            }}
            loading={{
              spinning: loadingList,
              indicator: <LoadingOutlined spin />,
            }}
          />
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default Page

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;

  .ant-form-item {
    margin-bottom: 0;
  }
`

const ActionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  svg {
    position: relative;
    top: 1px;
  }

  button {
    height: 40px;
  }

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
  }
`
