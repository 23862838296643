import { Col, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import { orderService } from 'services'

const AccountForm = ({ form }) => {
  const [packageList, setPackageList] = useState<any[]>([])

  useEffect(() => {
    orderService.getPackageList((data) => {
      setPackageList(data?.data?.packages)
    })
  }, [])

  return (
    <Form
      form={form}
      layout='horizontal'
      labelCol={{ span: 4, lg: { span: 3 } }}
      wrapperCol={{ span: 20, lg: { span: 21 } }}
      autoComplete='new-password'
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item label='Hỗ trợ viên' name='supporter'>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Mã người dùng' name='userCode'>
            <Input disabled placeholder='Mã người dùng' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Email' name='email'>
            <Input disabled placeholder='Email' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Tên đại diện' name='full_name' rules={[{ required: true, message: 'Vui lòng nhập Tên đại diện' }]}>
            <Input placeholder='Nhập Tên đại diện' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label='Số điện thoại'
            name='phone_number'
            rules={[{ required: true, message: 'Vui lòng nhập Số điện thoại' }]}
          >
            <Input placeholder='Nhập Số điện thoại' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Mật khẩu mới' name='password' rules={[{ min: 8, message: 'Trường mật khẩu tối thiểu 8 ký tự' }]}>
            <Input placeholder='Vui lòng để trống nếu không muốn thay đổi' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name='default_package_id' label='Gói mặc định'>
            <select className='custom-select'>
              <option value=''>Chọn gói</option>
              {packageList?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title} - {item.transporter?.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AccountForm
