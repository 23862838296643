/* eslint-disable consistent-return */
import { API_BASE_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

const search = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/list?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const create = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/create`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const update = async (id: string, body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/update/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const cancel = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/cancel?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getPackageList = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/transporter/package-list`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getTransporter = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/transporter/list`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getStatusList = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/get-status-order`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getDetail = async (id: number, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/detail?orderId=${id}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getListDetail = async (ids: string[], cbs?: (data) => void, cbe?: (data) => void) => {
  const params = new URLSearchParams()
  ids.forEach((id) => params.append('ids[]', id))

  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/list-by-id?${params.toString()}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getListDetailPublict = async (ids: string[], cbs?: (data) => void, cbe?: (data) => void) => {
  const params = new URLSearchParams()
  ids.forEach((id) => params.append('ids[]', id))

  return await fetchHelper
    .fetch(`${API_BASE_URL}/public/orders/list-by-id-public?${params.toString()}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const reOrder = async (id: string, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/reOrder/${id}`, {
      method: 'POST',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const cancelByIds = async (ids: string[], cbs?: (data) => void, cbe?: (data) => void) => {
  const params = new URLSearchParams()
  ids.forEach((id) => params.append('ids[]', id))

  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/cancel-by-ids?${params.toString()}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const searchOrderComplete = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/list-completed?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const updateStatus = async (id: string, body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/update-status/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const searchFilter = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/orders/filter?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

export default {
  search,
  cancel,
  create,
  update,
  getPackageList,
  getStatusList,
  getDetail,
  getListDetail,
  getListDetailPublict,
  reOrder,
  cancelByIds,
  searchOrderComplete,
  updateStatus,
  searchFilter,
  getTransporter,
}
