import TableBase from 'components/Table/TableBase'
import { LoadingOutlined } from '@ant-design/icons'
import { useHistoryOrderUpdateWeigh } from 'services/swr/order'
import { useState } from 'react'
import { Flex } from 'widgets/Box'
import { numberWithCommas } from 'utils'
import FilterForm from './FilterForm'

const HistoryTable = () => {
  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 10,
  })
  const { data, isLoading } = useHistoryOrderUpdateWeigh(params)

  const columns = [
    {
      title: 'Ngày điều chỉnh',
      dataIndex: 'created_at',
      render: (value) => value,
    },
    {
      title: 'Mã đơn vận',
      dataIndex: 'order_code',
      render: (value) => value,
    },
    {
      title: 'Trước điều chỉnh',
      dataIndex: 'from_package',
      render: (value, item) => (
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <p>{value}</p>
          <p>Khối lượng: {item?.weight_from}</p>
          <p>Giá vận: {numberWithCommas(item?.fee_from)}đ</p>
        </Flex>
      ),
    },
    {
      title: 'Sau điều chỉnh',
      dataIndex: 'to_package',
      render: (value, item) => (
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <p>{value}</p>
          <p>Khối lượng: {item?.weight_to}</p>
          <p>Giá vận: {numberWithCommas(item?.fee_to)}đ</p>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <FilterForm setParams={setParams} />

      <TableBase
        columns={columns}
        dataSource={data?.orders}
        scroll={{ x: 1000 }}
        rowKey={(row) => row.id}
        loading={{
          spinning: isLoading || !data,
          indicator: <LoadingOutlined spin />,
        }}
        pagination={{
          current: data?.meta?.current_page,
          pageSize: data?.meta?.per_page,
          total: data?.meta?.total,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setParams((prev) => ({
              ...prev,
              page: page,
              per_page: pageSize,
            }))
          },
          showTotal: (total) => `Tổng ${total}`,
        }}
      />
    </>
  )
}

export default HistoryTable
