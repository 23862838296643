/* eslint-disable consistent-return */
import { API_BASE_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

const search = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/logs/transporters?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

export default { search }
