import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input } from 'antd'
import ROUTER from 'services/router'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from 'services'
import styled from 'styled-components'
import ModalForgotPassword from './ModalForgotPassword'

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;
  border-radius: 5px;
  background: #fff;
  padding: 10px 25px 15px 25px;
  border-radius: 0px 5px 5px 0px;
  height: 100%;

  .control-form {
    display: flex;
    margin-bottom: 20px;

    button {
      width: 100%;
      border-radius: 15px;
    }
  }

  .warp-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    &.form-register {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .ant-form-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        font-weight: bold;
      }
    }

    .ant-form-item-explain-error {
      font-size: 12px;
    }

    .form-bottom {
      margin-top: 16px;

      p {
        text-align: center;
        font-size: 14px;
        margin-bottom: 12px;
      }

      button {
        margin-bottom: 7px;
      }

      .check-box-rule {
        font-size: 13px;
      }
    }
  }

  .box-success {
    text-align: center;

    img {
      margin-bottom: 5px;
    }

    p {
      text-align: left;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 5px;
    }

    a {
      display: block;
      background-color: #008848;
      border-color: #008848;
      color: #fff;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      padding: 10px;
      text-align: center;
    }
  }
`

const FormLogin = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [tab, setTab] = useState(0)
  const [heightInput, setHeightInput] = useState(40)
  const [isApprove, setIsApprove] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [notiSuccess, setNotiSuccess] = useState('')
  const [mailSuccess, setMailSuccess] = useState('')

  const handleShowModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const onFinish = useCallback(
    async (values) => {
      if (tab === 1) {
        await authService.register(
          values,
          (response) => {
            setNotiSuccess(response?.data?.message)
            setMailSuccess(response?.data?.email)
            form.resetFields()
          },
          (response) => {
            toast.error(response?.message || 'Tạo tài khoản thất bại')
          }
        )

        return
      }

      await authService.login(
        values,
        (response) => {
          history.push(ROUTER.adminHome)
          toast.success(response?.data?.message, {
            autoClose: 1000,
          })
        },
        (response) => {
          toast.error(response?.message || 'Đăng nhập thất bại')
        }
      )
    },
    [history, tab, form]
  )

  const onChangeTab = (val: number) => {
    setIsApprove(false)
    form.resetFields()
    setTab(val)
    toast.error('Ifyouareevelopinaproductionapplicationwerecommendupdating the configuration to enable type aware lint rules:')
  }

  const onFinishFailed = useCallback(() => {
    console.log('onFinishFailed')
  }, [])

  useEffect(() => {
    setHeightInput(tab === 1 ? 35 : 40)
  }, [tab])

  return (
    <>
      <WrapperForm>
        {!mailSuccess && (
          <>
            <div className='control-form'>
              <Button type={tab === 0 ? 'primary' : 'text'} onClick={() => onChangeTab(0)}>
                Đăng nhập
              </Button>
              <Button type={tab === 1 ? 'primary' : 'text'} onClick={() => onChangeTab(1)}>
                Tạo tài khoản
              </Button>
            </div>

            <div className={`warp-form ${tab === 1 ? 'form-register' : ''}`}>
              <Form
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                form={form}
              >
                {tab === 1 && (
                  <>
                    <Form.Item
                      label='Tên đại diện'
                      name='full_name'
                      rules={[{ required: true, message: 'Vui lòng nhập Tên đại diện!' }]}
                    >
                      <Input style={{ height: heightInput }} prefix={<UserOutlined />} placeholder='Nhập Tên đại diện!' />
                    </Form.Item>
                    <Form.Item
                      label='Số điện thoại'
                      name='phone_number'
                      rules={[{ required: true, message: 'Vui lòng nhập Số điện thoại!' }]}
                    >
                      <Input style={{ height: heightInput }} prefix={<PhoneOutlined />} placeholder='Nhập Số điện thoại!' />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    { required: true, message: 'Vui lòng nhập email!' },
                    {
                      type: 'email',
                      message: 'Email sai định dạng!',
                    },
                  ]}
                >
                  <Input style={{ height: heightInput }} prefix={<MailOutlined />} placeholder='Nhập email!' />
                </Form.Item>

                {tab === 0 && (
                  <Form.Item
                    label='Mật khẩu'
                    name='password'
                    rules={[
                      { required: true, message: 'Vui lòng nhập mật khẩu!' },
                      { min: 8, message: 'Trường mật khẩu tối thiểu 8 ký tự' },
                    ]}
                  >
                    <Input.Password
                      style={{ height: heightInput }}
                      prefix={<LockOutlined />}
                      placeholder='Nhập mật khẩu!'
                      autoComplete='false'
                    />
                  </Form.Item>
                )}
                {tab === 1 && (
                  <Form.Item
                    label='Mật khẩu'
                    name='password'
                    rules={[
                      { required: true, message: 'Vui lòng nhập mật khẩu!' },
                      { min: 8, message: 'Trường mật khẩu tối thiểu 8 ký tự' },
                    ]}
                  >
                    <Input style={{ height: heightInput }} prefix={<LockOutlined />} placeholder='Nhập mật khẩu!' />
                  </Form.Item>
                )}

                {tab === 1 && (
                  <div className='check-box-rule' style={{ marginBottom: 5 }}>
                    <Checkbox style={{ fontSize: 13 }} value={isApprove} onChange={() => setIsApprove((prev) => !prev)}>
                      Chấp nhận <span style={{ fontWeight: 'bold' }}>điều khoản và quy định</span> của Drugy
                    </Checkbox>
                  </div>
                )}

                <Form.Item>
                  <Button type='primary' htmlType='submit' style={{ width: '100%' }} disabled={tab === 1 && !isApprove}>
                    {tab === 0 ? 'Đăng nhập' : 'Tạo tài khoản'}
                  </Button>
                </Form.Item>
              </Form>

              {tab === 0 && (
                <div className='form-bottom'>
                  <div style={{ textAlign: 'right', fontSize: 14, marginBottom: 16, fontWeight: 'bold' }}>
                    <Link to='/' style={{ fontWeight: 'bold' }} onClick={handleShowModal}>
                      Quên mật khẩu?
                    </Link>
                  </div>

                  <p style={{ fontWeight: 'bold' }}>Bạn chưa có tài khoản?</p>

                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%', fontWeight: 'bold' }}
                    onClick={() => setTab(1)}
                  >
                    Đăng ký ngay
                  </Button>

                  <div className='check-box-rule'>
                    <Checkbox>Tôi đã hiểu và đồng ý với các điều khoản của Drugy - TOANQUOCPOST.VN</Checkbox>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {mailSuccess && (
          <div className='box-success'>
            <img width={80} src='/images/home/ok.png' alt='' />
            <p>
              {notiSuccess?.split(mailSuccess)?.[0]}
              <span style={{ color: '#1718DD' }}>{mailSuccess}</span>
              {notiSuccess?.split(mailSuccess)?.[1]}
            </p>
            <a href='/'>Quay về trang chủ</a>
          </div>
        )}
      </WrapperForm>

      <ModalForgotPassword open={isModalOpen} onCancel={handleShowModal} />
    </>
  )
}

export default FormLogin
